body {
    background-image: url("../assets/background.png"); /* The image used */
    /*background-repeat: no-repeat;*/
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
    overflow: hidden;
}

.centerLoading {
    display: flex;
    justify-content: space-between;
    position: absolute;
    margin-top: 18%;
    margin-left: 48%;
}

.lds-ring {

    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 7px solid #076df3;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #076df3 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 600px) {
    .body-child {
        max-width: 500px;
    }

    .header-mobile-money {
        width: 100%;
        position: relatve;
        margin-left: 0.2px;
    }
}

/*.rowbtncopay{*/
/*    visibility: hidden;*/
/*}*/
@media only screen
and (min-device-width: 320px)
and (max-device-width: 480px) {
    .closeCard {
        visibility: hidden;
    }

    .centerLoading {
        margin-top: 70%;
        margin-left: 40%;
    }

    /*.rowbtncopay{*/
    /*    visibility: visible;*/
    /*}*/
}

@font-face {
    font-family: poppinsExtraBold;
    src: url('../fonts/Poppins-ExtraBold.ttf');
}

@font-face {
    font-family: poppinsBold;
    src: url('../fonts/Poppins-Bold.ttf');
}

@font-face {
    font-family: poppinsRegular;
    src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: poppinsLight;
    src: url('../fonts/Poppins-Light.ttf');
}

@font-face {
    font-family: poppinsSemiBold;
    src: url('../fonts/Poppins-SemiBold.ttf');
}

.imageFooterLoading > img {
    height: 60px;
}

.titlecopayright > img {
    height: 15px;
    margin-top: 5px;
}

.imageFooterLoading {
    margin-top: 100%;
    margin-left: -140px;
    z-index: -1;
}

.titlecopay {
    font-family: poppinsBold;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.75px;
    text-align: left;
    color: #031926;
}

.titlecopayright {
    color: rgb(3, 25, 38);
    letter-spacing: 1.75pt;
    font-size: 15px;
}

.titlecopay, .titlecopayright {
    display: inline-block;
}

.containerHeader {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.homecopay {
    margin-top: 10%;
    display: relative;
    max-width: 80%;
    margin-left: 10%;
}

hr.line1 {
    border: 2px bold rgb(3, 25, 38);
}

.line2Total {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.35px;
    text-align: center;
    color: #031926;
    font-family: poppinsExtraBold;
    font-style: normal;
}

.line2montant {
    font-family: poppinsExtraBold;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.3px;
    text-align: center;
    color: #076df3;
}

.containerTotal {
    display: flex;
    justify-content: space-between;
}

.cardContainerTotal {
    border-radius: 12px;
    border: solid 2px #031926;
    background-color: #fff;
}

.line2Total, .line2montant {
    display: inline-block;
}

.litteTitle {
    font-family: poppinsRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: left;
    color: #031926;
    font-weight: 400;
}

.image-partenaire > img {
    height: 17px;
    margin-left: 7px;
    width: auto;
}

.litteTitle, .image-partenaire {
    display: inline-block;
}

.containerImage {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.alert-message {
    font-family: poppinsRegular;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.65px;
    text-align: left;
    color: #031926;
}

.container-alert-message {
    padding: 12px;
    border-radius: 11px;
    background-color: #efefef;
}

.wallet, .mobile {
    display: inline-block;

}

.parentCard {
    display: flex;
    justify-content: space-around;
}

.btn-moyen-paiement {
    position: relative;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.2em;
    width: 100%;
    display: inline-block;
    border-radius: 1.7em;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: black;
    background-color: white;
    border: solid 2px #031926;

}

.btn-moyen-paiement:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border: solid 2px rgb(7, 109, 243);
    border-radius: 2em;
}

.btn-moyen-paiement:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-moyen-paiement::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}

.btn-moyen-paiement::after {
    background-color: #fff;
}

.btn-moyen-paiement:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}
.btn-moyen-paiement > img{
    max-height: 55px;
}

@media (max-width: 300px) {

    .btn-moyen-paiement > img{
        max-height: 25px;
    }

}
.themeCardType {
    border-radius: 23px;
    border: solid 2px #031926;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    cursor: pointer;
}

.themeCardType:hover {
    border: 3px solid rgb(7, 109, 243);
    border-radius: 15px;
}

.imageType > img {
    display: flex;
    justify-content: center;
    height: 80px;
    padding: 10px;
    position: relative;
    margin-left: -10px;
    margin-top: -6px;
}

.imageType2 > img {
    display: flex;
    justify-content: center;
    position: relative;
    height: 85px;
    padding: 10px;
    margin-left: 2px;
    margin-top: -10px;
}
.imageType3>img {   
    display: flex;
    justify-content: center;
    height: 80px;
    padding: 10px;
    position: relative;
    margin-left: -15px;
    margin-top: -6px;   
}

.titleThemeCardType {
    display: flex;
    justify-content: space-around;
    font-family: poppinsRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #031926;
}

.footer {
    display: flex;
    justify-content: space-around;
}

.footer > img {
    height: 20px;
    width: 20px;
}

.lastTitle {
    font-family: poppinsLight;
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #031926;
}

.header-mobile-money {
    height: 55pt;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #031926
}

.header-mobile-money-parent {
    color: #fff;
    font-size: 15px;
}

.card-mobile-money {
    border-radius: 14px;
    border: 1.5px solid #031926;
    border-top: 1px solid #fff;
}

.header-mobile-money-child-leading, .closeCard {
    display: flex;
    justify-content: space-between;
}

.header-mobile-money-child-leading {
    cursor: pointer;
}

.leading-image, .leading-title {
    display: inline-block;
    font-family: PoppinsBold;
}

.leading-title {
    align-content: center;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-top: -2px;
}

.leading-image > img {
    width: 12px;
    margin-right: 10px;
}

.header-mobile-money-child {
    display: flex;
    justify-content: space-between;
}

.closeCard {
    font-family: poppinsLight;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-top: -2px;
}

.header-card-title {
    font-family: poppinsLight;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-top: -2px;
}

.body-mobile-money {
    padding-left: 25px;
    padding-right: 25px;
}

.row1tilte {
    margin-left: -12px;
    margin-bottom: 2px;
    font-family: poppinsSemiBold;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #031926;
}


.btn-copay {
    width: 100%;
    /*height: 54px;*/
    border-radius: 11px;
    background-color: #031926;
}

.btn-copay:disabled {

    background: #999;
    color: #555;
    cursor: not-allowed;
}

.btn-copay[disabled]:hover {
    background: #999;
    text-decoration: inherit;
}

.btn-copay[disabled]:active {
    box-shadow: inherit;
    transform: inherit;
}

.btn-copay > span {
    font-family: poppinsSemiBold;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.1px;
    text-align: center;
    color: #fff;
}

.btn-copay:hover, .button-paiement-valid:hover {
    background-color: #076df3;
    border: inherit;
    font-weight: 700;
}

.btn-copay:active, .button-paiement-valid:active {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    transform: translateY(2px);
}

.imgoption > img {
    height: 5px;
    width: 5px;
    z-index: -1;
    overflow: hidden;
}

.footer-title {
    font-family: poppinsSemiBold;
}

.imageFooter {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 1%;
}

.imageFooter > img {
    height: 60px;
}

.prepend-copay {
    position: absolute;
}
select{
    font-family: Chillax, poppinsSemiBold;
}
.input-copay {
    width: 100%;
    border: solid 1px #031926;
    border-left: inherit;
    border-radius: 11px;
    height: 54px;
    text-align: center;
    padding: 14px;
    font-family: Chillax, poppinsSemiBold;
}

.input-copay::placeholder {
    display: flex;
    justify-content: center;
}

.input-copay::-webkit-outer-spin-button,
.input-copay::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input-copay[type=number] {
    -moz-appearance: textfield;
}

.prepend-copay {
    width: 54px;
    height: 54px;
    border-radius: 12px;
    background-color: #fff;
    border: solid 1px #031926;
    display: flex;
    justify-content: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.prepend-copay > span {
    padding: 5px;
}

.prepend-copay > span > img {
    margin-top: 7px;
}

.select-copay {
    height: 54px;
    padding: 6px 12px 6px 9px;
    border-radius: 11px;
    border: solid 1px #031926;
    background-color: #fff;
}

.title-wallet {
    font-family: poppinsSemiBold;
    font-size: 15px;
    display: flex;
    justify-content: center;
}

.wallet-card {
    display: flex;
    justify-content: center;
}

.wallet-image {
    display: flex;
    justify-content: center;
}

.wallet-image > svg {
    /*height: 150px;*/
    /*width: 150px;*/
    /*position: relative;*/
}

.title-paiment-valid {
    font-family: PoppinsBold;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.85px;
    text-align: center;
    color: #031926;
}

.button-paiement-valid {
    width: 100%;
    height: 40px;
    border-radius: 11px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #031926;
    font-family: PoppinsBold;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.95px;
    text-align: center;
    color: #fff;
}

.button-paiement-valid > img {
    height: 18px;
    display: flex;
    justify-content: left;
    position: absolute;
    margin-left: 4px;
}

.card-paiement-infos {
    border-radius: 11px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #031926;
}

.title-card-paiement {
    font-family: PoppinsBold;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.85px;
    text-align: center;
    color: #fff;
}

.little-card-paiement-recu {
    font-family: PoppinsLight;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.85px;
    text-align: center;
    color: #fff;
    margin-left: 2px;
}

.paiement-image {
    display: flex;
    justify-content: center;
}

.paiement-image > img {
    /*height: 70px;*/
    /*width: 100px;*/
}

.errorMessage {
    color: red;
    margin-left: 2px;
    margin-top: 5px;
}

.moyen-de-payment {
    transition: visibility 0s, opacity 1s linear;
    opacity: 1;
    visibility: visible;
    position: relative;
}

.moyen-de-payment.hide {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    /*pointer-events: none;*/
    /*display: ;*/
}



.btn-copay {
    --color: #076df3;
    --dark-color: #031926;
    font-family: Chillax;
    display: inline-block;
    width: 100%;
    height: 2.6em;
    line-height: 2.5em;
    /*margin: 20px;*/
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    font-size: 17px;
    border-radius: 6px;
    font-weight: 500;
    background-color: var(--color) !important;
    color: var(--dark-color);
}

.btn-copay:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--dark-color);
    height: 150px;
    width: 115%;
    border-radius: 50%;
}

.btn-copay:hover {
    color: #fff;
}

.btn-copay:before {
    top: 100%;
    left: 100%;
    transition: all .5s;
}

.btn-copay:hover:before {
    top: -50px;
    left: -25px;
}

.btn-copay:active:before {
    background: #3a0ca3;
    transition: background 0s;
}

.cursor-pointer{
    cursor: pointer;
}