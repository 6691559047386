.main{
  min-height: 500px;
  margin: 0px auto;
  width: auto;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  margin-top: -30px;
}
@font-face {
  font-family: poppinsExtraBold;
  src: url('../../../fonts/Poppins-ExtraBold.ttf');
}
.text-not-found{
  text-align: center;
  font-size: 30px;
  margin-top: 50px;
  font-family: poppinsExtraBold;
}
@media only screen
    and (min-device-width : 320px) 
    and (max-device-width : 480px) {
    .main>img{
      width: 350px;
    }
}